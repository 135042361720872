body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  background: linear-gradient(
      180deg,
      #595757,
      #595757,
      #3f3d3d,
      #161616,
      #121212
    )
    fixed;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
}

.streamPage {
  background: #202331;
  max-height: 100vh;
  padding-top: 10px;
  min-height: calc( 100vh - 113px);
}

.navBar {
  position: absolute;
  width: 100%;
}

.component {
  padding-top: 113px;
}

.heading {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;

  color: #595959;
}

.balance-box {
  background: #0d1530;
  border-radius: 4px;
  width: 200px;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: white;
}



.Embed_videoEmbed__5R5ux{
    min-height: 600px;
}
.StreamPage_yolo__A0oAU{
    height: 600px;
}

.StreamPage_streamPage__3-Erh{
    justify-content:  start;
}

.StreamPage_text-color-blue__3i5Eu{
    color: blue !important;
}

.Header_example-enter__5-TKR {
    opacity: 0.01;
  }
  
  .Header_example-enter__5-TKR.Header_example-enter-active__2OcwW {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  
  
  .Header_example-exit__1frJg {
    opacity: 1;
  }
  
  
  .Header_example-exit__1frJg.Header_example-exit-active__2rMs6 {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
.SignUpInfo_Name__34G8d {
  text-align: center;
  align-content: center;
}

