.yolo{
    height: 600px;
}

.streamPage{
    justify-content:  start;
}

.text-color-blue{
    color: blue !important;
}
